<template>
  <v-app>
    <v-content>
      <v-container fluid fill-height style="background-color: #C52C34">
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4 xl3>
            <v-card rounded class="pa-4 elevation-1">
              <v-layout align-center justify-center class="pt-4">
                <p class="title">{{ $t('login.titulo') }}</p>
              </v-layout>
              <v-card-text>
                <v-form v-model="valid" ref="form" @submit="signUp">
                  <v-text-field
                    data-cy="email"
                    :label="$t('login.email')"
                    name="login"
                    prepend-icon="email"
                    type="email"
                    v-model="email"
                    :rules='emailRules'
                    required
                  ></v-text-field>

                  <v-text-field
                    data-cy="password"
                    :label="$t('login.password')"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    v-model="password"
                    :rules='fieldRules'
                    required
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-layout justify-end>
                    <v-btn block data-cy="signin" class="mt-4 mb-2 pa-4" type="submit" color="primary" :disabled="!valid" @click="signIn">{{ $t('login.entrar') }}</v-btn>
                  </v-layout>
                </v-form>
                <v-layout align-center justify-center class="pt-4">
                  <v-btn data-cy="signup" flat type="submit" small class="ma-0" @click="forgotPassword">
                    {{ $t('login.esqueciMinhaSenha') }}
                  </v-btn>
                </v-layout>
                <!-- <v-layout align-center justify-center class="pt-4">
                  <v-btn data-cy="signup" flat type="submit" small class="ma-0" @click="signUp">
                    {{ $t('login.naoPossuoConta') }}
                  </v-btn>
                </v-layout> -->
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import firebase from 'firebase/app'
import util from '@/router/util'
import SnackbarService from '@/components/snackbar/SnackbarService'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Login',
  data: () => {
    return {
      valid: false,
      email: null,
      password: null,
      fieldRules: [],
      emailRules: []
    }
  },
  beforeMount () {
    if (this.isAuthenticated) {
      this.$router.push(util.path.research.TAB_HISTORY)
    }
  },
  mounted () {
    this.fieldRules = [v => !!v || this.$t('validacao.campoObrigatorio')]
    this.emailRules = [
      v => !!v || this.$t('validacao.campoObrigatorio'),
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || this.$t('validacao.emailValido')
      }
    ]
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    ...mapActions(['fetchUser']),
    signUp () {
      this.$router.push(util.path.SIGNUP)
    },
    signIn (e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        firebase.auth().signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            let user = firebase.auth().currentUser
            this.fetchUser(user)
            if (user.emailVerified) {
              this.$router.push(util.path.research.TAB_HISTORY)
            } else {
              SnackbarService.on('Snackbar', this.$t('login.emailVerificacao.mensagem'))
            }
          })
          .catch(error => {
            console.debug(error)
            SnackbarService.on('Snackbar', this.$t('validacao.invalido'))
          })
      }
    },
    forgotPassword () {
      this.$router.push(util.path.research.FORGOT_PASSWORD)
    }
  }
}
</script>
